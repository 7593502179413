import React, { ReactElement, useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { defaultLang } from '@system/translations'
import { Job } from '@/types/job'
import { useIntl } from 'react-intl'

import useCustomCursor from '@system/hooks/useCustomCursor'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@components/modules/global/container'
import Headline from '@components/text/headline'
import Module from '@components/core/module'
import Paragraph from '@components/text/paragraph'
import Paginator from '@components/core/pagination'
import SelectBox from '@components/core/formfields/selectBox'
import TextInput from '@components/core/formfields/textInput'

import useGlobalText from '@system/hooks/useGlobalText'

import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles((theme) => ({
  jobListRoot: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(4),
    },
  },
  jobListForm: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin: theme.spacing(-1),

    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(-4),
    },
  },
  jobListSearch: {
    width: '100%',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  jobListSearchInput: {
    width: '100%',
    boxSizing: 'border-box',
  },
  jobListFilter: {
    width: '100%',
    padding: theme.spacing(1),
    boxSizing: 'border-box',

    [theme.breakpoints.up('md')]: {
      width: '50%',
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%',
    },
  },
  jobListSelect: {
    margin: 0,
  },
  jobListHeadlineEmpty: {
    margin: theme.spacing(11, 0, 11, 0),
  },
  jobListHeadline: {
    margin: theme.spacing(16, 0, 8, 0),

    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(30, 0, 11, 0),
    },
  },
  jobListList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  jobListEntry: {
    margin: theme.spacing(12, 0, 12, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(20, 0, 20, 0),
    },
  },
  jobListEntryTitle: {
    margin: 0,
  },
  jobListEntryMeta: {
    margin: 0,
  },
  jobListPagination: {
    marginTop: theme.spacing(11),
  },
}))

interface Filter {
  value: string
  text: string
}

export type JobListProps = DBN.IReactDefaultProps & {
  pageContext?: DBN.PageHelpers.PageContext
  theme?: string
  anchor?: string
}

export default function JobList({
  pageContext,
  theme,
  anchor,
}: JobListProps): ReactElement {
  const classes = useStyles()
  const { getText } = useGlobalText()
  const [loaded, setLoaded] = useState<boolean>(false)
  const locale = pageContext?.locale || defaultLang

  const jobsPerPage = 8

  const [jobs, setJobs] = useState<Array<Job>>([])
  const [departments, setDepartments] = useState<Array<Filter>>([])
  const [offices, setOffices] = useState<Array<Filter>>([])

  const [filteredJobs, setFilteredJobs] = useState<Array<Job>>([])
  const [officeFilter, setOfficeFilter] = useState<string>('')
  const [departmentFilter, setDepartmentFilter] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [pagedJobs, setPagedJobs] = useState<Array<Job>>([])
  const [page, setPage] = useState<number>(1)

  const joblistScrollRef = useRef<HTMLDivElement>(null)
  const joblistRef = useRef<HTMLDivElement>(null)

  const intl = useIntl()
  const { setCursorType } = useCustomCursor()

  const searchHandler = (event: Event): void => {
    const search = event?.target?.value ? event.target.value : ''
    const filtered = jobs.filter((job: Job) => {
      if (departmentFilter && job.department != departmentFilter) {
        return false
      }
      if (officeFilter && job.office != officeFilter) {
        return false
      }
      if (search && job.name.toLowerCase().indexOf(search.toLowerCase()) < 0) {
        return false
      }
      return true
    })
    sessionStorage.setItem('sff-job-search-term', search)
    setSearchTerm(search)
    setFilteredJobs(filtered)
    setPagedJobs(filtered.slice(0, jobsPerPage))
    setPage(1)
  }

  const officeFilterHandler = (value: string) => {
    const office = value == 'ALL' ? '' : value
    const filtered = jobs.filter((job: Job) => {
      if (departmentFilter && job.department != departmentFilter) {
        return false
      }
      if (office && job.office != office) {
        return false
      }
      if (
        searchTerm &&
        job.name.toLowerCase().indexOf(searchTerm.toLowerCase()) < 0
      ) {
        return false
      }
      return true
    })

    sessionStorage.setItem('sff-job-filter-office', office)
    setOfficeFilter(office)
    setFilteredJobs(filtered)
    setPagedJobs(filtered.slice(0, jobsPerPage))
    setPage(1)
  }

  const departmentFilterHandler = (value: string) => {
    const department = value == 'ALL' ? '' : value
    const filtered = jobs.filter((job: Job) => {
      if (department && job.department != department) {
        return false
      }
      if (officeFilter && job.office != officeFilter) {
        return false
      }
      if (
        searchTerm &&
        job.name.toLowerCase().indexOf(searchTerm.toLowerCase()) < 0
      ) {
        return false
      }
      return true
    })
    sessionStorage.setItem('sff-job-filter-departent', department)
    setDepartmentFilter(department)
    setFilteredJobs(filtered)
    setPagedJobs(filtered.slice(0, jobsPerPage))
    setPage(1)
  }

  const easeInOutQuint = (t: number) => {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
  }

  const paginationHandler = (event: React.ChangeEvent, page: number) => {
    if (joblistScrollRef.current && joblistRef.current) {
      const start = window.scrollY || window.pageYOffset
      const target =
        start + joblistScrollRef.current.getBoundingClientRect().top - 100
      const time = Date.now()
      const duration = 1500

      ;(function step() {
        const dx = Math.min(1, (Date.now() - time) / duration)

        const opacity =
          dx < 0.5 ? 1 - Math.min(1, dx * 3) : Math.min(1, dx * 3 - 2)
        const position =
          start + (target - start) * easeInOutQuint(Math.min(1, dx * 1.5))
        window.scrollTo(0, position)
        joblistRef.current.style.opacity = opacity.toString()
        if (dx < 1) {
          requestAnimationFrame(step)
        }
      })()

      setTimeout(() => {
        setPagedJobs(
          filteredJobs.slice((page - 1) * jobsPerPage, page * jobsPerPage)
        )
        setPage(page)
      }, duration / 2)
    } else {
      setPagedJobs(
        filteredJobs.slice((page - 1) * jobsPerPage, page * jobsPerPage)
      )
      setPage(page)
    }
  }

  const extractDepartments = (jobs: Array<Job>) => {
    return jobs
      .map((job) => {
        return job.department
      })
      .filter((department, index, array) => {
        return department !== null && array.indexOf(department) == index
      })
      .map((department) => {
        return {
          value: department || '',
          text: getText(`job.department.${department}`) || department,
        }
      })
  }

  const extracOffices = (jobs: Array<Job>) => {
    return jobs
      .map((job) => {
        return job.office
      })
      .filter((office, index, array) => {
        return office !== null && array.indexOf(office) == index
      })
      .map((office) => {
        return {
          value: office || '',
          text: getText(`job.office.${office}`) || office,
        }
      })
  }

  useEffect(() => {
    const search = sessionStorage.getItem('sff-job-search-term') || ''
    const department = sessionStorage.getItem('sff-job-filter-departent') || ''
    const office = sessionStorage.getItem('sff-job-filter-office') || ''

    axios
      .get(
        `${
          process.env.GATSBY_JOB_SERVICE_URL
        }/api/jobs?lang=${locale.toUpperCase()}`
      )
      .then((res) => {
        const jobs = res.data
        setJobs(jobs)
        setDepartments(extractDepartments(jobs))
        setOffices(extracOffices(jobs))
        setSearchTerm(search)
        setDepartmentFilter(department)
        setOfficeFilter(office)

        const filtered = jobs.filter((job: Job) => {
          if (department && job.department != department) {
            return false
          }
          if (office && job.office != office) {
            return false
          }
          if (
            search &&
            job.name.toLowerCase().indexOf(search.toLowerCase()) < 0
          ) {
            return false
          }
          return true
        })
        setFilteredJobs(filtered)
        setPagedJobs(filtered.slice(0, jobsPerPage))
        setPage(1)
      })
      .catch((err) => {
        console.error(err)
      })
      .then(() => {
        setLoaded(true)
      })
  }, [])

  return loaded ? (
    <Module theme={theme} anchor={anchor} className={classes.jobListRoot}>
      {
        <Container type="nomargin">
          {jobs.length < 1 ? (
            <Headline level={2} className={classes.jobListHeadlineEmpty}>
              {intl.formatMessage({ id: 'job.list.empty' })}
            </Headline>
          ) : (
            <>
              <div className={classes.jobListForm}>
                <div className={classes.jobListSearch}>
                  <TextInput
                    type="text"
                    onKeyUp={searchHandler}
                    placeholder={intl.formatMessage({
                      id: 'job.list.search.placeholder',
                    })}
                    className={classes.jobListSearchInput}
                    endicon={<SearchIcon />}
                    clearable
                    initialValue={searchTerm}
                  />
                </div>
                {offices.length > 0 && (
                  <div className={classes.jobListFilter}>
                    <SelectBox
                      label={getText('module.jobList.offices') || 'Offices'}
                      onChange={officeFilterHandler}
                      items={offices}
                      className={classes.jobListSelect}
                      initialValue={officeFilter}
                    />
                  </div>
                )}
                {departments.length > 0 && (
                  <div className={classes.jobListFilter}>
                    <SelectBox
                      label={
                        getText('module.jobList.departments') || 'Departments'
                      }
                      onChange={departmentFilterHandler}
                      items={departments}
                      className={classes.jobListSelect}
                      initialValue={departmentFilter}
                    />
                  </div>
                )}
              </div>
              <div ref={joblistScrollRef}>
                <Headline level={3} className={classes.jobListHeadline}>
                  {filteredJobs.length < 1
                    ? intl.formatMessage(
                        { id: 'job.list.headline.none' },
                        { count: filteredJobs.length }
                      )
                    : filteredJobs.length > 1
                    ? intl.formatMessage(
                        { id: 'job.list.headline.plural' },
                        { count: filteredJobs.length }
                      )
                    : intl.formatMessage(
                        { id: 'job.list.headline.singular' },
                        { count: filteredJobs.length }
                      )}
                </Headline>
                <div ref={joblistRef}>
                  <ul className={classes.jobListList}>
                    {pagedJobs.map((job: Job, index: number) => (
                      <li className={classes.jobListEntry} key={`job-${index}`}>
                        <Headline
                          level={2}
                          className={classes.jobListEntryTitle}
                        >
                          <Link
                            to={
                              locale === defaultLang
                                ? `/jobs/${job.slug}`
                                : `/${locale}/jobs/${job.slug}`
                            }
                            onMouseEnter={() => setCursorType('link')}
                            onMouseLeave={() => setCursorType('')}
                            onClick={() => setCursorType('')}
                          >
                            {job.name}
                          </Link>
                        </Headline>
                        {job.office && job.department ? (
                          <Paragraph className={classes.jobListEntryMeta}>
                            {(getText(`job.office.${job.office}`) ||
                              job.office) +
                              ', ' +
                              (getText(`job.department.${job.department}`) ||
                                job.department)}
                          </Paragraph>
                        ) : (
                          <Paragraph className={classes.jobListEntryMeta}>
                            {job.office &&
                              (getText(`job.office.${job.office}`) ||
                                job.office)}
                            {job.department &&
                              (getText(`job.department.${job.department}`) ||
                                job.department)}
                          </Paragraph>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {filteredJobs.length > jobsPerPage && (
                <Paginator
                  count={Math.ceil(filteredJobs.length / jobsPerPage)}
                  onChange={paginationHandler}
                  page={page}
                  className={classes.jobListPagination}
                ></Paginator>
              )}
            </>
          )}
        </Container>
      }
    </Module>
  ) : (
    <></>
  )
}
