import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { InView } from 'react-intersection-observer'
import Container from '@components/modules/global/container'
import Module from '@components/core/module'

const useStyles = makeStyles((theme) => ({
  textColumnRoot: {
    padding: theme.spacing(12, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(30, 0),
    },
  },
  textColumnWrapper: {
    opacity: 0,
    transform: 'translate(0, 10vh)',
    transition: 'transform 0.6s ease-out',
  },
  textColumnWrapperFadeIn: {
    opacity: 1,
    transform: 'translate(0, 0)',
  },
}))

export type TextColumnProps = DBN.IReactDefaultProps & {
  theme?: string
}

export default function TextColumn({
  theme,
  children,
  ...props
}: TextColumnProps): ReactElement {
  const classes = useStyles()

  return (
    <>
      <InView threshold={0} triggerOnce={true}>
        {({ inView, ref }) => (
          <Module theme={theme} className={classes.textColumnRoot} {...props}>
            <div ref={ref}>
              <Container
                type="nomargin"
                className={clsx(classes.textColumnWrapper, {
                  [classes.textColumnWrapperFadeIn]: inView,
                })}
              >
                {children}
              </Container>
            </div>
          </Module>
        )}
      </InView>
    </>
  )
}
