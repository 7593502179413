import React, { ReactElement, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Module from '@components/core/module'
import Slider from '@components/core/slider'

const useStyles = makeStyles(() => ({
  galleryRoot: {},
  galleryImage: {
    overflow: 'hidden',
  },
  galleryImageZoom: {
    display: 'block !important',
    transition: 'transform 0.6s ease-out',
  },
  galleryImageAnimate: {
    '& $galleryImageZoom': {
      transform: 'scale(1.05)',
      transition: 'transform 0.6s ease-in',
    },
  },
}))

export type GalleryProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: string
  anchor?: string
  headline?: string
  images?: Array<DBN.Contentful.IAsset>
}

export default function Gallery({
  theme,
  anchor,
  headline,
  images,
}: GalleryProps): ReactElement {
  const classes = useStyles()
  const [animating, setAnimating] = useState(false)

  return (
    <Module theme={theme} anchor={anchor} className={classes.galleryRoot}>
      <Slider
        headline={headline}
        onSlideNextTransitionStart={() => setAnimating(true)}
        onSlidePrevTransitionStart={() => setAnimating(true)}
        onSlideChangeTransitionEnd={() => setAnimating(false)}
      >
        {images &&
          images?.map((img, index) => (
            <div
              key={`gallery-item-${index}`}
              className={clsx(classes.galleryImage, {
                [classes.galleryImageAnimate]: animating,
              })}
            >
              {getImage(img) && (
                <GatsbyImage
                  image={getImage(img)}
                  alt={img?.description || ''}
                  title={img.title}
                  className={classes.galleryImageZoom}
                  layout="cover"
                />
              )}
            </div>
          ))}
      </Slider>
    </Module>
  )
}
