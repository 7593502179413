import React, { ReactElement } from 'react'
import { BLOCKS, MARKS, INLINES, Block } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Options } from '@contentful/rich-text-react-renderer'
import { HistoryLocation } from '@reach/router'
import parse from 'html-react-parser'

import { makeStyles } from '@material-ui/core/styles'
import FontSize from '@config/theme/definitions/fontSize'

import ContentfulComponent from './ContentfulComponent'
import Headline from '@components/text/headline'
import Paragraph from '@components/text/paragraph'
import UnorderedList from '@components/text/unorderedlist'
import OrderedList from '@components/text/orderedlist'
import Separator from '@components/core/separator'
import Link from '@components/core/link'
import Button from '@components/core/button'
import Download from '@components/media/download'
import TextColumn from '@components/modules/content/M028-TextColumn'

import PageModule from './PageModule'

const useStyles = makeStyles((theme) => ({
  teaserLink: {
    fontFamily: theme.typography.fontFamily,
    fontSize: FontSize['lg'],
    lineHeight: 1.11111,
  },
}))

export type ContentfulComponentArrayProps = {
  location?: HistoryLocation
  pageContext?: DBN.PageHelpers.PageContext
  componentarray: DBN.Contentful.BasicRichTextType
  simple?: boolean
  button?: boolean
  teaser?: boolean
  container?: boolean
  theme?: string
}

export default function ContenfulComponentArray({
  location,
  pageContext,
  componentarray,
  simple,
  button,
  teaser,
  container,
  theme,
}: ContentfulComponentArrayProps): ReactElement {
  const classes = useStyles()

  const options: Options = {
    renderMark: {
      // eslint-disable-next-line react/display-name
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    },
    renderText: (text) => {
      return parse(
        text
          .replaceAll('\n', '<br>')
          .replaceAll('®', '<sup>®</sup>')
          .replaceAll('©', '<sup>©</sup>')
          .replaceAll('℗', '<sup>℗</sup>')
      )
    },
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (simple) {
          return children
        } else {
          return <Paragraph>{children}</Paragraph>
        }
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),
      // eslint-disable-next-line react/display-name
      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedList>{children}</OrderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => children,
      // eslint-disable-next-line react/display-name
      [BLOCKS.HR]: () => <Separator />,
      // eslint-disable-next-line react/display-name
      [BLOCKS.QUOTE]: (node) => {
        return !!node?.content ? (
          <ContentfulComponent
            component={
              {
                __typename: 'blockquote',
                ...node,
              } as Block & DBN.Contentful.INodeDefaults
            }
          />
        ) : (
          <></>
        )
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_1]: (node, children) => (
        <Headline level={1}>{children}</Headline>
      ),
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_2]: (node, children) => (
        <Headline level={2}>{children}</Headline>
      ),
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_3]: (node, children) => (
        <Headline level={3}>{children}</Headline>
      ),
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_4]: (node, children) => (
        <Headline level={4}>{children}</Headline>
      ),
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_5]: (node, children) => (
        <Headline level={5}>{children}</Headline>
      ),
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        if (node.data.container) {
          return container ? (
            <TextColumn theme={theme}>{children}</TextColumn>
          ) : (
            <>{children}</>
          )
        }
        if (node.data.target) {
          return container ? (
            <PageModule
              key={`${node.data.target.id}`}
              location={location}
              pagemodule={node.data.target}
              pageContext={pageContext}
            />
          ) : (
            <></>
          )
        }
        console.warn('Missing embedded entry', node)
        return <></>
      },
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: (node, children) => {
        if (button) {
          return (
            <Button
              {...(theme === 'dark' ? { type: 'secondary' } : {})}
              to={node.data.uri}
              isExternal
            >
              {children}
            </Button>
          )
        }
        if (teaser) {
          return (
            <Link
              to={node.data.uri}
              isExternal={true}
              className={classes.teaserLink}
            >
              {children}
            </Link>
          )
        }

        return (
          <Link to={node.data.uri} isExternal>
            {children}
          </Link>
        )
      },
      // eslint-disable-next-line react/display-name
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const link = node.data?.target?.fields?.fullPath

        if (button && link) {
          return (
            <Button
              {...(theme === 'dark' ? { type: 'secondary' } : {})}
              to={link}
            >
              {children}
            </Button>
          )
        }
        if (teaser && link) {
          return (
            <Link to={link} isExternal={false} className={classes.teaserLink}>
              {children}
            </Link>
          )
        }
        return link ? <Link to={link}>{children}</Link> : children
      },
      // eslint-disable-next-line react/display-name
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const link = node.data?.target?.file?.url
        const type = node.data?.target?.file?.contentType.split('/')[1]

        return link ? (
          <Download to={link}>
            {children} {type && `(${type})`}
          </Download>
        ) : (
          { children }
        )
      },
    },
  }

  type DocumentNode = {
    nodeType: string
    data?: Record<string, unknown>
    content?: Array<DocumentNode>
    value?: string
  }

  const document = JSON.parse(componentarray.raw)
  const content: Array<DocumentNode> = []
  let index = 0

  document.content.forEach(
    (component: DocumentNode, componentIndex: number) => {
      switch (component.nodeType) {
        case BLOCKS.EMBEDDED_ENTRY:
          if (content[index]) {
            index++
          }
          content[index] = component
          index++
          break
        case BLOCKS.PARAGRAPH:
          // remove empty last paragraph
          if (componentIndex == document.content.length - 1) {
            if (
              component.content &&
              component.content.length == 1 &&
              component.content[0].nodeType == 'text' &&
              component.content[0].value == ''
            ) {
              break
            }
          }
        default:
          if (!content[index]) {
            content[index] = {
              data: {
                container: true,
              },
              content: [],
              nodeType: 'embedded-entry-block',
            }
          }
          content[index]?.content?.push(component)
      }
    }
  )

  const manipulatedarray = {
    raw: JSON.stringify({
      data: document.data,
      content: content,
      nodeType: document.nodeType,
    }),
    references: componentarray.references,
  }

  return <>{renderRichText(manipulatedarray, options)}</>
}
